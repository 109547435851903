header {
    &[role="banner"] {
        @import "header/navbar-top";
        @import "header/navbar-main";
        @import "header/navbar-page";
        @import "header/nav-breadcrumb";
        @import "header/nav-side-menu";
        background-color: $white;
        position: sticky;
        top: 0;
        transition: top linear 0.2s;
        width: 100%;
        z-index: $zindex-sticky;

        @include media-breakpoint-up(lg) {
            @at-root .scrolled-down & {
                top: rem(-48);
            }
        }

        @media (prefers-reduced-motion: reduce) {
            transition-duration: 0;
        }
    }
}
