&.card-experience-var3 {
    --#{$prefix}card-bg: transparent;
    --#{$prefix}card-color: #{$dark};
    --#{$prefix}card-cap-bg: transparent;

    .card-footer {
        padding: 0.5rem 0;
    }

    .stretched-link {
        font-weight: 600;
    }
}
