.caption-block {
    @include flex-center;
    flex-direction: column;

    &-icon {
        i {
            @include font-size(30px);
        }
    }

    &-title {
        @include flex-center;
        flex-direction: column;

        h3 {
            display: flex;
            font-weight: 500;
            justify-content: center;
            margin-bottom: rem(30px);
            padding-top: 3rem;
            position: relative;
            text-align: center;

            &:before {
                @include font-size(17px);
                content: attr(data-content);
                font-weight: 600;
                position: absolute;
                top: 1rem;
            }
        }

        .btn {
            align-self: center;
        }
    }

    @include media-breakpoint-up(lg) {
        align-items: baseline;
        flex-direction: row;
        padding: rem(70px) 0 rem(20px);

        &-icon {
            display: flex;
            justify-content: center;
            max-width: rem(40px);
            width: 100%;
        }

        &-title {
            max-width: map-get($container-max-widths, "md");

            h3 {
                margin-bottom: 3rem;
                text-align: left;
                padding-top: 0;
                position: static;

                &:before {
                    @include font-size(80px);
                    font-weight: 400;
                    left: 50%;
                    max-width: calc(map-get($container-max-widths, "xxl") - (2 * $container-padding-x));
                    opacity: 0.03;
                    padding: 0 20px;
                    position: absolute;
                    text-transform: uppercase;
                    transform: translateX(-50%);
                    top: rem(86px);
                    width: 100%;
                }
            }
        }
    }
}
