.nav-main {
    flex-direction: column;

    .nav-link {
        @include font-size(16px);
        @include line-height(16px, 18px);
        color: $gray-900;
        display: flex;
        font-weight: 600;

        &:hover,
        &:active,
        &:focus {
            color: $primary;
            text-decoration: none;
        }
    }

    [aria-expanded] {
        .fa {
            transition: transform ease-in 0.2s;
        }
    }

    [aria-expanded="true"] {
        color: $primary;

        .fa {
            transform: scaleY(-1);
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 0;

        .nav-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0;

            .nav-link {
                flex: 1 1 calc(100% - 40px);
                text-transform: none;
            }

            .nav-icon {
                @include font-size(16px);
                align-items: center;
                background-color: $primary-050;
                border: 1px solid $primary;
                color: $primary;
                display: flex;
                flex: 0 0 40px;
                height: 40px;
                justify-content: center;
                margin: 0 16px 0 0;
                text-decoration: none;
                width: 40px;
            }
        }

        .nav-link {
            justify-content: space-between;
        }

        .navbar-nav {
            background-color: $white;
            padding: 0;

            > .nav-item {
                border-bottom: 1px solid $primary-050;
            }

            .nav-link {
                padding: rem(16) rem(26);
            }
        }

        .nav {
            background-color: $primary-025;
            border-bottom: 1px solid $primary-050;
            border-top: 1px solid $primary-050;

            &.nav-images {
                .nav-link {
                    align-items: center;
                    flex-direction: row-reverse;
                    gap: 1rem;
                    justify-content: flex-end;

                    .img-fluid {
                        max-width: 100px;
                    }
                }
            }

            .nav-item {
                border-bottom: 1px solid $primary-050;
                display: flex;
                flex: 1 0 100%;
                flex-direction: column;

                &:last-child {
                    border-bottom: none;
                }

                .nav-title {
                    .nav-icon {
                        border-color: $primary-125;
                    }
                }
            }

            .nav {
                border-bottom: none;
                padding: 1rem;

                .nav-item {
                    border-bottom: none;
                }

                .nav-link {
                    @include font-size(14px);
                    padding: rem(10) rem(26);
                }
            }
        }

        .back {
            display: none;
        }

        .all {
            align-items: center;
            background-color: $primary-025;
            color: $primary;
            display: flex;
            font-weight: 600;
            padding: rem(16) rem(26);

            .fa {
                margin-left: rem(10);
            }
        }

        [data-bs-parent="#navbar-nav"] {
            > *:nth-child(n + 2) {
                display: none;
            }
        }

        .offcanvas-header {
            justify-content: flex-end;
            padding: rem(26);
        }

        .offcanvas-body {
            background-color: $primary-050;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            padding: 0;

            > .btn {
                margin: rem(16) rem(26);
            }
        }

        .nav-main-body {
            overflow-y: auto;
        }

        .nav-main-footer {
            display: flex;
            flex-direction: column;

            .btn {
                margin: rem(16) rem(26);
            }
        }

        .nav-top {
            padding: rem(10);

            .nav-link {
                padding: 0.5rem 1rem;
            }
        }

        .nav-portal {
            display: flex;
            justify-content: center;
            padding: rem(26);

            .nav-link {
                padding: 0 rem(10);
                text-decoration: underline;

                &[aria-current="page"] {
                    color: $primary;
                    text-decoration: none;
                }

                + .nav-link {
                    border-left: 1px solid $gray-900;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex !important;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0;

        @at-root .navigation-open {
            overflow: hidden;
            padding-right: rem(17);

            &:after {
                backdrop-filter: blur(5px);
                background-color: rgba($primary, 0.75);
                content: "";
                height: 100vh;
                left: 0;
                position: fixed;
                top: 0;
                width: 100vw;
                z-index: 9;
            }
        }

        &.offcanvas {
            border-color: $primary-050;
            bottom: auto;
            left: auto;
            position: unset;
            right: auto;
            top: auto;
            transform: none;
            visibility: visible !important;
            width: auto;
        }

        .offcanvas-header {
            display: none;
        }

        .offcanvas-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding-left: rem(16);
            padding-right: 0;
        }

        + .offcanvas-backdrop {
            display: none;
        }

        .nav-main-body {
            display: flex;
            flex-direction: row;
        }

        .nav-main-footer {
            .nav-portal,
            .nav-top,
            .nav-language {
                display: none;
            }
        }

        .navbar-nav {
            align-items: center;
            display: flex;
            flex-direction: row;

            > .nav-item {
                > .nav-link {
                    align-items: center;
                    padding-left: rem(16);
                    padding-right: rem(16);

                    span {
                        margin-right: rem(7);
                    }
                }

                .nav-title {
                    align-items: center;
                    display: flex;
                    margin-bottom: 0;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    text-transform: none;

                    .nav-link {
                        margin-right: rem(7);
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .nav-icon {
                        @include font-size(16px);
                        background-image: none;
                        border: none;
                        color: $primary;
                        padding: 0;
                    }
                }
            }
        }

        .nav {
            display: flex;
            gap: rem(40);
            padding: rem(50) 0 rem(30);

            .nav-link {
                color: $primary;
                flex-direction: column;
                letter-spacing: 0.005em;
                text-transform: uppercase;
            }

            .nav-icon {
                display: none;
            }

            .nav {
                flex-direction: column;
                gap: 0;
                padding: 0;
                position: unset;
                width: auto;

                .nav-item {
                    flex: 1;
                }

                .nav-link {
                    color: $gray-900;
                    font-weight: 400;
                    text-transform: none;
                }
            }

            &.nav-images {
                gap: rem(20);

                > .nav-item {
                    flex: 0 1 rem(200);

                    > .nav-link {
                        color: $gray-900;
                        height: 100%;
                        justify-content: space-between;
                        text-transform: none;
                    }

                    span {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        [id*="collapse-"] {
            background-color: $white;
            left: 0;
            max-height: calc(100vh - 145px);
            overflow-y: auto;
            overscroll-behavior: contain;
            position: absolute;
            top: rem(97);
            width: 100vw;
            z-index: 999;

            &.collapsing {
                overflow: hidden;
            }
        }

        .back,
        .all {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        .nav-main-footer {
            .btn {
                margin-left: rem(20);
            }
        }
    }
}
