.video-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @supports (aspect-ratio: 16/8) {
        aspect-ratio: 16/8;
    }
}

.video-hero {
    opacity: 0;
    transition: opacity ease-in 250ms;

    @supports (aspect-ratio: 16/9) {
        aspect-ratio: 16/9;
    }

    &.loaded {
        opacity: 1;
    }
}