&.carousel-experience-fade {
    .carousel-indicators {
        gap: rem(72);
        margin: 0 5%;
        opacity: 0;
        overflow: hidden;
        position: relative;

        &.loaded {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
    }

    .carousel-indicator {
        border-width: 0;
        border-bottom: 2px solid transparent;
        display: none;
        flex-direction: column;
        height: auto;
        justify-content: space-between;
        margin: 0;
        opacity: 0.5;
        padding: rem(70) 0 rem(40);
        transition: all, ease-in 0.2s;
        width: 100%;

        &.active,
        &.indicator-next,
        &.indicator-prev {
            display: block;
            opacity: 1;
        }

        &.indicator-next:not(.indicator-start),
        &.active.indicator-end {
            transform: translateX(100%);
        }

        &.indicator-prev:not(.indicator-end),
        &.active.indicator-start {
            transform: translateX(-100%);
        }

        [data-bs-target] {
            text-indent: 0;
            height: auto;
            opacity: 1;
            width: 100%;
        }
    }

    .title {
        margin-bottom: 1.5rem;
        text-transform: uppercase;
    }

    @include media-breakpoint-up(md) {
        .carousel-indicator {
            &.active {
                border-color: $primary;
            }

            &.visible {
                display: flex;
            }
        }
    }
}
