ol {
    &:not(.breadcrumb) {
        counter-reset: item;
        padding-left: rem(35.45);

        li {
            list-style-type: none;
            margin: rem(6) 0;
            position: relative;

            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                left: -2.215625rem;
                position: absolute;
            }
        }
    }
}
