.related-list {
    margin: 0 0 2rem;

    p {
        margin-bottom: rem(10);
    }

    .related-item {
        margin: 2rem 0;
    }

    .btn-wrapper {
        display: flex;
        gap: rem(10);
    }

    .btn {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: space-between;

        .icon-chevron-right {
            @include font-size(10px);
        }
    }
}
