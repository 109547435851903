.play-text {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    border: 1px solid $primary-400;
    background-color: transparentize($white, .8);
    backdrop-filter: blur(2px);
    border-radius: 50%;
    padding: rem(30);
    // center by default
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: opacity .5s .5s ease-out,  left .7s .5s ease-out, top .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out, padding .7s .5s ease-out;

    .section-in-view &, .page-builder & {
        width: 128px;
        height: 128px;
        opacity: 1;
        top: calc(50% - 64px);
        left: calc(50% - 64px);
        &:hover {
            transition: padding .2s ease-out, left .2s ease-out, top .2s ease-out,  width .2s ease-out, height .2s ease-out;
            padding: rem(40);
            width: 152px;
            height: 152px;
            top: calc(50% - 76px);
            left: calc(50% - 76px);
        }
    }
    span {
        color: $white;
        font-weight: 600;
        opacity: 0;
        transition: opacity .8s .4s ease-out;
        .section-in-view &, .page-builder & {
           opacity: 1;
        }
    }

    // variants 
    .play-right-center & {
        left: auto;
        right: -1px;
        transition: opacity .5s .5s ease-out,  right .7s .5s ease-out, top .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out, padding .7s .5s ease-out;
        .section-in-view &, .page-builder & {
            left: auto;
            right: -15px;
            &:hover {
                transition: padding .2s ease-out , right .2s ease-out, top .2s ease-out,  width .2s ease-out, height .2s ease-out;
                padding: rem(40);
                left: auto;
                right: -27px;
            }
        } 
    }

    .play-left-center & {
        right: auto;
        left: -1px;
        transition: opacity .5s .5s ease-out, left .7s .5s ease-out, top .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out,  padding .7s .5s ease-out;
        .section-in-view &, .page-builder & {
            left: -15px;
            right: auto;
            &:hover {
                transition: padding .2s ease-out , .2s ease-out, top .2s ease-out,  width .2s ease-out, height .2s ease-out;
                padding: rem(40);
                left: -27px;
                right: auto;
            }
        } 
    }

    .play-top-center & {
        left: calc(50% - 50px);
        top: -1px;
        transition: opacity .5s .5s ease-out, top .7s .5s ease-out, left .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out,  padding .7s .5s ease-out;
        .section-in-view &, .page-builder & {
            top: -15px;
            left: calc(50% - 64px);
            &:hover {
                transition: padding .2s ease-out , .2s ease-out, top .2s ease-out, left .2s ease-out,  width .2s ease-out, height .2s ease-out;
                padding: rem(40);
                top: -27px;
                left: calc(50% - 76px);
            }
        } 
    }

    .play-bottom-center & {
        left: calc(50% - 50px);
        top: auto;
        bottom: -1px;
        transition: opacity .5s .5s ease-out, bottom .7s .5s ease-out, left .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out,  padding .7s .5s ease-out;
        .section-in-view &, .page-builder & {
            bottom: -15px;
            top: auto;
            left: calc(50% - 64px);
            &:hover {
                transition: padding .2s ease-out , .2s ease-out, bottom .2s ease-out, left .2s ease-out,  width .2s ease-out, height .2s ease-out;
                padding: rem(40);
                top: auto;
                bottom: -27px;
                left: calc(50% - 76px);
            }
        } 
    }

    @include media-breakpoint-down(md) {
        .play-mobile-top-right & {
            right: 34px;
            left: auto;
            top: -1px;
            transition: opacity .5s .5s ease-out, top .7s .5s ease-out, right .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out,  padding .7s .5s ease-out;
            .section-in-view &, .page-builder & {
                top: -15px;
                left: auto;
                right: 20px;
                &:hover {
                    transition: padding .2s ease-out , .2s ease-out, top .2s ease-out, right .2s ease-out,  width .2s ease-out, height .2s ease-out;
                    padding: rem(40);
                    top: -27px;
                    left: auto;
                    right: 8px;
                }
            } 
        }

        .play-mobile-top-left & {
            left: 34px;
            right: auto;
            top: -1px;
            transition: opacity .5s .5s ease-out, top .7s .5s ease-out, left .7s .5s ease-out,  width .7s .5s ease-out, height .7s .5s ease-out,  padding .7s .5s ease-out;
            .section-in-view &, .page-builder & {
                top: -15px;
                right: auto;
                left: 20px;
                &:hover {
                    transition: padding .2s ease-out , .2s ease-out, top .2s ease-out, left .2s ease-out,  width .2s ease-out, height .2s ease-out;
                    padding: rem(40);
                    top: -27px;
                    right: auto;
                    left: 8px;
                }
            } 
        }

    }
}