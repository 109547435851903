.hero {
    @import "hero/hero-wide";
    @import "hero/hero-wide-overlap";
    @import "hero/hero-side";
    @import "hero/hero-viewport";
    background-color: transparent;
    color: $dark;
    position: relative;

    .hero-media {
        img,
        video {
            height: auto;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
}
