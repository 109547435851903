.card {
    @import "cards/amani-full-width";
    @import "cards/amani-image";
    @import "cards/amani-text";
    @import "cards/article-highlight";
    @import "cards/article-search-result";
    @import "cards/article-var1";
    @import "cards/article-var2";
    @import "cards/article-var3";
    @import "cards/experience-var1";
    @import "cards/experience-var2";
    @import "cards/experience-var3";
    @import "cards/info-usp";
    @import "cards/product-var1";
    @import "cards/product-var1-2";
    @import "cards/product-var2";
    @import "cards/product-var3";
    @import "cards/search-result";
    overflow: hidden;

    .card-img,
    .card-img-bottom,
    .card-img-top {
        height: auto;
    }

    &[href] {
        &:hover,
        &:active,
        &:focus {
            .card-title {
                text-decoration: underline;
            }
        }
    }

    &:focus-visible {
        .card-title {
            text-decoration: underline;
        }
    }
}
