/*
 * font-weight: 100; // Thin (Hairline)
 * font-weight: 200; // Extra Light (Ultra Light)
 * font-weight: 300; // Light
 * font-weight: 400; // Regular
 * font-weight: 500; // Medium
 * font-weight: 600; // Semi Bold (Demi Bold)
 * font-weight: 700; // Bold
 * font-weight: 800; // Extra Bold (Ultra Bold)
 * font-weight: 900; // Black (Heavy)
 */

/*
 * icon font with icomoon
 */
@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?r6j6bu") format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?r6j6bu") format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?r6j6bu##{$icomoon-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak-as: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up-right-and-arrow-down-left-from-center {
    &:before {
        content: $icon-arrow-up-right-and-arrow-down-left-from-center;
    }
}

.icon-badge-check {
    &:before {
        content: $icon-badge-check;
    }
}

.icon-diagram-venn-diagonal {
    &:before {
        content: $icon-diagram-venn-diagonal;
    }
}

.icon-bezier-curve {
    &:before {
        content: $icon-bezier-curve;
    }
}

.icon-circle-user {
    &:before {
        content: $icon-circle-user;
    }
}

.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}

.icon-hand-holding-seedling {
    &:before {
        content: $icon-hand-holding-seedling;
    }
}

.icon-house {
    &:before {
        content: $icon-house;
    }
}

.icon-magnifying-glass {
    &:before {
        content: $icon-magnifying-glass;
    }
}

.icon-check-circle {
    &:before {
        content: $icon-check-circle;
    }
}

.icon-circle-dollar {
    &:before {
        content: $icon-circle-dollar;
    }
}

.icon-circle-euro {
    &:before {
        content: $icon-circle-euro;
    }
}

.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}

.icon-location-crosshairs {
    &:before {
        content: $icon-location-crosshairs;
    }
}

.icon-location-dot {
    &:before {
        content: $icon-location-dot;
    }
}

.icon-mobile-screen-button {
    &:before {
        content: $icon-mobile-screen-button;
    }
}

.icon-wrench {
    &:before {
        content: $icon-wrench;
    }
}

.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}

.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}

.icon-times {
    &:before {
        content: $icon-times;
    }
}

.icon-bars {
    &:before {
        content: $icon-bars;
    }
}

.icon-renson-home-waves {
    &:before {
        content: $icon-renson-home-waves;
    }
}

.icon-circle-phone {
    &:before {
        content: $icon-circle-phone;
    }
}

.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}

.icon-eye {
    &:before {
        content: $icon-eye;
    }
}

.icon-type-mechanical-ventilation {
    &:before {
        content: $icon-type-mechanical-ventilation;
    }
}

.icon-type-carports {
    &:before {
        content: $icon-type-carports;
    }
}

.icon-type-wall-cladding {
    &:before {
        content: $icon-type-wall-cladding;
    }
}

.icon-type-garden-elements {
    &:before {
        content: $icon-type-garden-elements;
    }
}

.icon-type-diy-louvres {
    &:before {
        content: $icon-type-diy-louvres;
    }
}

.icon-type-amani {
    &:before {
        content: $icon-type-amani;
    }
}

.icon-type-smart-living {
    &:before {
        content: $icon-type-smart-living;
    }
}

.icon-type-sunprotection-screens {
    &:before {
        content: $icon-type-sunprotecion;
    }
}

.icon-type-terrace-cover {
    &:before {
        content: $icon-type-amani;
    }
}

.icon-type-amani {
    &:before {
        content: $icon-type-terrace-cover;
    }
}

.icon-type-ventilation {
    &:before {
        content: $icon-type-ventilation;
    }
}

.icon-type-solar-shading {
    &:before {
        content: $icon-type-solar-shading;
    }
}

.icon-type-window-vents {
    &:before {
        content: $icon-type-window-vents;
    }
}
