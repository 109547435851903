@mixin flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin ellipsis ($max-width) {
  display: inline-block !important;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

@mixin line-height($font-size, $height) {
  line-height: line-height($font-size, $height);
}

@mixin ripple($radial) {
  background-position: center;
  background-size: 0%;
  transition: background-size 1s;

  &:focus,
  &:active {
    background-image: radial-gradient(circle, transparent 1%, $radial 1%);
    background-size: 15000%;
  }
}
