.rich-text,
.fr-view {
    a {
        &:not(.btn) {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-up(lg) {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @at-root .bg-primary & {
        color: $white;
    }

    @at-root .blog-detail & {
        + picture img {
            margin: rem(30px) 0;
        }
    }
}
