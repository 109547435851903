&.hero-wide-overlap {
    .hero-media {
        @include flex-center;

        img,
        video {
            aspect-ratio: 16/7;
        }
    }

    .hero-content {
        position: relative;
    }

    .bg-offset {
        width: 75%;
        h1 {
            @include font-size(42px);
            font-weight: 300;
            text-transform: uppercase;
            margin-top: rem(30);
        }

    }

    @include media-breakpoint-up(xl) {
        .hero-media {
            img,
            video {
                max-height: rem(460);
                width: 100%;
            }
        }

        .bg-offset {
            position: relative;
            padding: rem(85) rem(15px) rem(75) rem(75);

            h1 {
                margin-top: 0;
                position: relative;
                width: 100%;
            }

            &:after {
                background-color: $gray-100;
                content: '';
                height: calc(100% + rem(200));
                width: calc(100% + ((100vw - 141.66667% ) / 2 ));
                left: calc(((100vw - 141%) / 2) * -1);
                top: calc(rem(-200));
                position: absolute;
                z-index: -1;
            }
        }
    }
}
