.navbar-top {
    @import "navbar-top/nav-portal";
    @import "navbar-top/nav-search";
    @import "navbar-top/nav-top";
    @import "navbar-top/nav-user";
    @import "navbar-top/navbar-toggler";
    padding: 0;
    top: 0;

    .nav-link,
    .dropdown-toggle,
    .form-control {
        @include font-size(14px);
        border: 1px solid transparent;
        color: $gray-900;
        display: flex;
        font-weight: 600;
        line-height: 1.5;
        padding: rem(10) rem(12);

        &:hover,
        &:active,
        &:focus {
            color: $primary-250;
        }
    }

    .nav-end {
        display: flex;
        align-items: center;
    }

    i[aria-hidden="true"] {
        @include font-size(16px);
    }

    @include media-breakpoint-up(lg) {
        background-color: $light;
        border-bottom: 1px solid $gray-250;
        height: rem(48);
        z-index: $zindex-popover;

        > [class*="container"] {
            align-items: stretch;
            gap: rem(24);

            .nav-start {
                @include flex-center;
                padding: rem(2) 0;
            }

            .nav-end {
                @include flex-center;
                border-left: 1px solid $gray-250;
                padding: rem(2) 0;
            }
        }

        @at-root .scrolled-down & {
            z-index: -1;
        }
    }
}
