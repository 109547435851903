&.card-article-search-result {
    margin: 0;

    .card-header {
        flex: 1 1 100%;
        padding: 0;
        overflow: hidden;
    }

    .card-body {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .badge-group {
            padding: 1rem 0;
        }
    }

    .card-title {
        @extend .h6;
    }

    picture {
        aspect-ratio: 364 / 210;
        display: flex;
        height: 100%;
        width: 100%;
    }

    .card-img {
        flex: 1;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    .card-footer {
        background-color: transparent;
        padding: 1rem 0;
    }

    .stretched-link {
        font-weight: 600;
    }

    @include media-breakpoint-down(md) {
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .card-header {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
        margin-bottom: 3rem;

        .card-header {
            flex-basis: 33.33%;
        }

        .card-body {
            flex: 1 1 66.66%;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}
