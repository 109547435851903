.search-bar {
    flex: 1;

    .form-control {
        border-color: $primary;
        border-radius: 5px 0 0 5px;

        &:focus {
            border-color: $blue;
        }
    }

    .input-group-btn {
        display: flex;
    }

    .btn {
        border-radius: 0 5px 5px 0;
    }
}
