&.card-search-result {
    border-width: 1px;
    border-color: $primary-050;
    margin-bottom: rem(8);

    .card-body {
        display: flex;
        gap: 1rem;
        padding: rem(24);
    }

    .card-details {
        flex-grow: 1;
    }

    .card-cta {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        gap: rem(16);
        justify-content: space-between;
    }

    .card-title {
        text-decoration: none;
        font-weight: 700;
    }

    .card-address {
        margin-bottom: rem(5);
    }

    .card-distance {
        @include font-size(12px);
        font-style: italic;
        color: $blue;

        a {
            color: $blue;
            text-decoration: underline;
        }
    }

    .card-type, .card-showroom {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: rem(10);

        .icon {
            flex: 0;
            font-size: rem(22);
            margin-right: rem(8);
        }

        span {
            flex: 1;
            display: block;
        }
    }

    .card-showroom {
        .icon {
            color: $primary;
            margin-right: 0;
            font-size: rem(14);
            &-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: $gray-200;
                border-radius: 50%;
                padding: rem(4px);
                width: rem(22px);
                height: rem(22px);
                margin-right: rem(8);
                flex-grow: 0;
            }
        }
    }

    &[data-dealer-type="RENSON_PREMIUM_AMBASSADOR"] {
        .card-body {
            border-left: 2px solid $secondary;
        }

        .card-type {
            color: $orange;
        }
    }

    &[data-dealer-type="RENSON_AMBASSADOR"] {
        .card-body {
            color: $primary;
        }
    }
    &[data-dealer-type="RENSON_FABRICATOR"] {
        .card-type {
            color: $fabricator-color;
        }
    }

    &[data-dealer-type="RENSON_SALES_POINT"] {
        .icon {
            display: none;
        }
    }

    &:hover,
    &:active,
    &:focus {
        .card-title {
            text-decoration: none;
        }
    }

    &.active {
        background-color: $gray-100;
        border-color: $primary-050;
    }

    @include media-breakpoint-down(sm) {
        .card-body {
            flex-direction: column;
            padding: rem(20);
        }

        .card-details,
        .card-cta {
            flex: 0 0 100%;
            width: 100%;
        }

        .card-cta {
            .btn,
            .card-type, .card-showroom {
                flex: 1 0 100%;
                width: 100%;
            }
        }
    }
}
