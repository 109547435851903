&.card-product-var1-2 {
    @extend .card-product-var1;

    .card-footer {
        padding: rem(20px) 0;
    }

    .form-check-label {
        color: $primary;
    }

    @at-root .bg-primary & {
        .form-check-label,
        .check {
            color: var(--#{$prefix}card-color);
        }
    }
}
