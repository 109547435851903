.accordion {
    --#{$prefix}accordion-color: $accordion-color;
    --#{$prefix}accordion-btn-padding-x: 0;
    --#{$prefix}accordion-body-padding-x: 0;

    &-button {
        @include font-size(20px);
    }

    &-body {
        @extend .rich-text;
    }

    @include media-breakpoint-down(lg) {
        &-button {
            font-weight: 600;
            padding-left: 0;
            padding-right: 0;
        }

        &-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        &-button {
            @include font-size(24px);
        }
    }
}