&.card-article-var1 {
    --#{$prefix}card-bg: transparent;
    --#{$prefix}card-spacer-x: 0;
    --#{$prefix}card-spacer-y: 0;
    --#{$prefix}card-cap-bg: transparent;
    --#{$prefix}card-cap-padding-x: 0;
    --#{$prefix}card-cap-padding-y: 0.625rem;
    height: 100%;

    .card-title {
        @include h6;
    }

    @at-root .bg-primary & {
        --#{$prefix}card-color: #{$white};

        .card-header {
            .badge {
                &.badge-outline-primary {
                    color: var(--#{$prefix}card-color);
                    border-color: var(--#{$prefix}card-color);
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .card-header {
            display: flex;
            flex-direction: column;
        }

        .card-body {
            padding: 0;
        }

        .card-footer {
            padding: 0;
        }

        .card-title {
            flex: 1 1 100%;
        }

        .stretched-link {
            font-weight: 600;
        }
    }
}
