&:not(.search) {
    select.form-control,
    .dropdown-toggle {
        @extend .form-select;
    }

    .dropdown-toggle {
        @extend .form-control;
        min-height: 50px;
        text-align: left;

        &:active,
        &:focus {
            border-color: transparentize($color: $primary, $amount: 0.9);
        }

        &::after {
            content: none !important;
        }

        &.is-valid,
        &.is-invalid {
            > span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 40px);
                word-break: normal;
            }
        }
    }

    .dropdown-menu {
        border: 1px solid transparentize($color: $primary, $amount: 0.9);
        border-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        width: 100%;

        li {
            padding: 0;
            border-top: 1px solid transparentize($color: $primary, $amount: 0.9);

            &:first-of-type {
                padding-top: 0;
                border-top: 0;
            }

            label {
                font-size: 1rem;
                font-weight: 300;
                display: block;
                padding: 0.6rem 1rem;
                margin: 0;
                cursor: pointer;
            }

            .dropdown-item {
                padding: 0;
                white-space: initial;

                &:active {
                    --#{$prefix}dropdown-link-color: #{$white};
                }
            }

            input[type="radio"] {
                display: none;
            }
        }
    }
}
