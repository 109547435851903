ul {
    &.check {
        padding-left: rem(35.45);

        li {
            list-style-type: none;
            margin: rem(6) 0;
            position: relative;

            &:before {
                @include font-size(20px);
                color: $teal;
                content: "#{$fa-var-circle-check}";
                font: var(--#{$fa-css-prefix}-font-regular);
                left: rem(-35.45);
                line-height: 1.25;
                position: absolute;
            }
        }

        @at-root .bg-primary & {
            li {
                &:before {
                    color: $white;
                }
            }
        }
    }

    &.right-arrow {
        padding-left: 0;

        li {
            break-inside: avoid-column;
            list-style-type: none;
            padding: rem(3) 0;

            &:after {
                color: $dark;
                content: "#{$fa-var-arrow-right}";
                font: var(--#{$fa-css-prefix}-font-solid);
                left: 0.5rem;
                line-height: 1.25;
                position: relative;
            }

            &:focus-within {
                &:after {
                    color: $primary-250;
                }
            }

            &.active {
                a {
                    color: $primary-250;
                    text-decoration: $primary-250 dotted underline;
                    text-decoration-thickness: 1px;
                }

                &:after {
                    color: $primary-250;
                }
            }
        }

        @at-root .bg-primary & {
            li {
                &:before {
                    color: $white;
                }
            }
        }
    }

    &.col-split-2 {
        columns: 2;
    }

    &.numbered-icons {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }
        .number {
            background-color: $primary;
            color: $white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 32px;
            top: 1rem;
        }
        svg, img {
            width: 55%;
        }
        .list-text {
            display: block;
            margin-top: 1.5rem;
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                &:not(:last-of-type) {
                    margin-bottom: 2rem;
                }
                
            }
            .number {
                left: 0;
            }

            .list-text {
               margin-top: 0;
            }
    
        }
    }

    &.link-icons {
        list-style: none;
        padding: 0;
        li {
            border: 1px solid $primary-600;
            &, a {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                color: $primary;
                font-weight: 600;
            }
            a {
                width: 100%;
                padding: 1rem 1rem 1rem 2rem;
                text-decoration: none !important;
                &:hover {
                    text-decoration: none !important;
                }
            }
            &:not(:last-of-type) {
                border-bottom: 0;
            }
        }

    }

    @include media-breakpoint-up(sm){
        &.col-split-2-sm {
            columns: 2;
        }    
    }

    @include media-breakpoint-up(md){
        &.col-split-2-md {
            columns: 2;
        }    
    }

    @include media-breakpoint-up(lg){
        &.col-split-2-lg {
            columns: 2;
        }    
    }

    @include media-breakpoint-up(xl){
        &.col-split-2-xl {
            columns: 2;
        }    
    }

    @include media-breakpoint-up(xxl){
        &.col-split-2-xxl {
            columns: 2;
        }    
    }
}
