.dealer {
    @import "dealer/search";
    @import "dealer/map";
    @import "dealer/results";
    @import "dealer/detail";

    &-modal-title {
        display: none !important;
    }
}
