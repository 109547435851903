&.card-amani-text{
    border: 1px solid $primary-600;
    height: 100%;

    .rich-text {
        padding: 3rem;
        @include media-breakpoint-down(md) { 
            padding: 1rem;
        }
    }

    
} 