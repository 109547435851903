.filter-categories {
    > .btn-group {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
    }

    .btn {
        border-radius: 5px;

        &.btn-outline-primary {
            --#{$prefix}btn-active-color: #{$white};
            --#{$prefix}btn-active-bg: #{$primary};
            --#{$prefix}btn-active-border-color: #{$primary};
            --#{$prefix}btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), 0.1);
        }

        [data-count] {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .btn-group {
            flex-basis: 100%;
        }

        .btn {
            width: 100%;
        }
    }
}
