$utilities: map-merge(
    $utilities,
    (
        "background-color": (
            property: background-color,
            class: bg,
            values: $grays,
        ),
    )
);

.bg-gray {
    background-color: $primary-050;

    &[class*="bg-offset-"] {
        &:after {
            background-color: $primary-050;
        }
    }
}

.bg-light {
    &[class*="bg-offset-"] {
        &:after {
            background-color: $gray-100;
        }
    }
}

.bg-primary {
    --#{$prefix}body-color: #{$white};

    &[class*="bg-offset-"] {
        &:after {
            background-color: $primary;
        }
    }
}

// BG OFFSET
[class*="bg-offset-"] {
    background-color: transparent !important;
    position: relative;
    z-index: 1;

    &:after {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    @include media-breakpoint-up(lg) {
        @at-root section#{&} {
            margin: 5rem 0;
            padding: 0;

            + [class*="bg-offset-"] {
                margin-top: 10rem;
            }
        }
    }
}

[class*="bg-offset-start"] {
    &:after {
        left: 0;
    }

    @include media-breakpoint-up(lg) {
        &:after {
            width: 82.5%;
        }

        @at-root section#{&} {
            &:after {
                width: 75%;
            }
        }
    }
}

[class*="bg-offset-end"] {
    &:after {
        right: 0;
    }

    @include media-breakpoint-up(lg) {
        &:after {
            width: 82.5%;
        }

        @at-root section#{&} {
            &:after {
                width: 75%;
            }
        }
    }
}

[class*="bg-offset-center"] {
    @include media-breakpoint-up(lg) {
        &:after {
            left: 50%;
            max-width: calc(map-get($container-max-widths, "xxl") - (2 * $container-padding-x));
            transform: translateX(-50%);
            width: 100%;
        }
    }
}

[class*="bg-offset-half-start"] {
    @include media-breakpoint-up(lg) {
        &:after {
            left: -50%;
            width: calc(100% + $container-padding-x);
        }

        @at-root section#{&} {
            padding: rem(70px) 0;
        }
    }
}

[class*="bg-offset-half-end"] {
    @include media-breakpoint-up(lg) {
        &:after {
            left: 50%;
            width: calc(100% + $container-padding-x);
        }

        @at-root section#{&} {
            padding: rem(70px) 0;
        }
    }
}
