.bg-gradient-warning {
    background: linear-gradient(
        270deg,
        rgba($warning, 0.15) 0%,
        rgba(131, 0, 79, 0.5) 100%
    );
}

.bg-gradient-success {
    background: linear-gradient(
        270deg,
        rgba($success, 0.15) 0%,
        rgba(77, 128, 0, 0.5) 100%
    );
}

.bg-gradient-info {
    background: linear-gradient(
        270deg,
        rgba($info, 0.15) 0%,
        rgba(80, 142, 204, 0.5) 100%
    );
}

.bg-gradient-purple {
    background: linear-gradient(
        270deg,
        rgba($purple, 0.35) 0%,
        rgba($purple, 0.65) 100%
    );
}
