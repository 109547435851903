.nav-search {
    .form-control {
        background-color: transparent;
        font-weight: 400;
        max-width: 0;
        transition: max-width 0.2s ease-in;

        &:focus {
            background-color: transparent;
            box-shadow: none;
            max-width: rem(200);

            + .input-group-btn {
                .btn {
                    color: $primary-250;
                }
            }
        }
    }

    .input-group-btn {
        display: flex;
    }

    .btn {
        @include flex-center;

        &:hover,
        &:active,
        &:focus {
            color: $primary-250;
        }
    }

    &:focus-within {
        background-color: $white;
    }

    @include media-breakpoint-up(lg) {
        .form-control {
            max-width: rem(80);
        }
    }
}
