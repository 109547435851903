.region-selector-item {
    a {
        display: flex;
        flex-direction: row;
        align-items: center;

        .fi,
        .fa {
            font-size: rem(21px);
            width: rem(21px);
            line-height: rem(30px);
            margin-right: rem(10px);

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &-country {
        font-size: rem(16px);
        @include media-breakpoint-down(sm) {
            font-size: rem(14px);
        }


    }

    &-language {
        font-size: rem(12px);
    }

}