&.card-experience-var2 {
    .card-img-overlay {
        display: flex;
        padding: 0;
    }

    .card-img {
        transition: all ease-in 0.2s;
    }

    .card-footer {
        align-self: flex-end;
        background-color: rgba($primary, 0.1);
        flex: 1;
        padding: 1rem 0.5rem;
        text-align: center;
        transform: translateY(0);
        transition: all ease-in 0.2s;
    }

    .card-title {
        color: $white;
        text-shadow: 0px 4px 4px rgba($black, 0.25);
        text-transform: uppercase;
    }

    .stretched-link {
        font-weight: 600;
    }

    &:hover,
    &:active,
    &:focus {
        .card-img {
            transform: scale(1.1);
        }

        .card-footer {
            transform: translateY(0);
        }

        .card-title {
            text-decoration: none;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-footer {
            transform: translateY(100%);
        }
    }
}
