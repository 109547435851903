&.card-product-var1 {
    --#{$prefix}card-bg: transparent;
    --#{$prefix}card-cap-bg: transparent;
    --#{$prefix}card-color: #{$dark};
    --#{$prefix}card-cap-padding-y: 0;
    height: 100%;

    .card-title {
        text-decoration: underline 2px transparent;
        transition: text-decoration ease-in 0.2s;

        &.h2 {
            color: $primary-250;
            margin-bottom: rem(20px);
        }
    }

    .check {
        color: $primary;
        font-weight: 600;

        li {
            &:before {
                line-height: 1.5;
            }
        }
    }

    .card-header {
        position: relative;
    }

    .card-header,
    .card-body {
        padding-left: 0;
        padding-right: 0;
    }

    picture {
        aspect-ratio: 16/9;
        display: flex;
        overflow: hidden;
    }

    .card-img {
        overflow: hidden;
        transform: scale(1);
        transform-origin: center;
        transition-delay: 500ms;
        transition-property: transform;
        transition-duration: 1500ms;
        transition-timing-function: ease-in;
    }

    .badge {
        bottom: rem(15px);
        position: absolute;
        transition: background-color ease-in 0.2s;
        right: rem(15px);
        text-transform: none;
    }

    &[href] {
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;

            .badge {
                background-color: $primary-250 !important;
            }

            .card-title {
                text-decoration: underline 3px $primary-250;
            }

            .card-img {
                transform: scale(1.2);
            }
        }
    }

    @at-root .bg-primary & {
        --#{$prefix}card-color: #{$white};

        .card-title {
            &.h2 {
                color: $primary-050;
            }
        }
    }
}
