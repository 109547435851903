@use 'sass:math';

.page-nav {
    .title {
        @include line-height($h2-font-size, 52.5px);
        margin-bottom: rem(16);
    }

    .nav-link {
        @include font-size(16px);
        font-weight: 700;
    }

    @include media-breakpoint-down(lg) {
        .title {
            margin-bottom: rem(20);
        }

        .navbar {
            padding: 0;
        }

        .nav-link {
            padding: 0.25rem 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-nav {
            flex-wrap: wrap;
            margin: 0 math.div(-$navbar-nav-link-padding-x, 2) rem(-28);
        }
    }
}
