.modal {
    &.gallery-modal {
        background-color: transparentize($color: $black, $amount: .1);
        .modal-dialog {
        width: 100%;
        max-width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        }
        .modal-content {
            position: relative;
            background-color: transparent;
            width: calc(100vw - 45px);
            height: calc(100vh - 45px);
            border: none;
            padding: 0;

            @supports(height: 100dvh) {
                height: calc(100dvh - 45px);
            }
        }

        .modal-header {
            border: none;
            position: absolute;
            top: -15px;
            right: -15px;
            z-index: 2;
            .btn-close {
                opacity: .8;
                color: $white;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") ;
                &:hover {
                    opacity: 1;
                }
            }
            @include media-breakpoint-down(md) {
                top: 30px;
                right: 30px;
                @supports(height: 100dvh) {
                   top: 0;
                   right: 0;
                }
            }
        }

        .modal-body {
            max-width: 100%;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;

            img, video, picture {
                max-width: 100%;
                max-height: 100%;
            }

            // bs carousel inside modal
            .carousel {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &-inner {
                    height: 100%;
                }
                
                &-item {
                    height: 100%;
                    opacity: 0;
                    &.active {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        opacity: 1;

                        .carousel-media{
                            flex: 1;
                            position: relative;
                            img, video, picture {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                object-fit: contain;
                                bottom: 0;
                                margin: auto;
                            }
                        }

                        .carousel-caption{
                            position: relative;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            padding: 1rem 0 1.5rem;
                            p {
                                font-size: 1rem;
                                margin: 0;
                            }
                        }
                    }
                }


                // arrows
                &-control-next-icon, &-control-prev-icon {
                    width: 80px;
                    height: 80px;
                    border: 1px solid $white;
                    border-radius: 50%;
                    background-color: transparentize($white, .8);
                    backdrop-filter: blur(2px);
                    background-image: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(18px);
                    flex-shrink: 0;
                    @extend %fa-icon;
                    @extend .fa-solid;
                    @include media-breakpoint-down(md) {
                        width: 40px;
                        height: 40px;
                        font-size: rem(14px);
                    }
                    
                }
                &-control-next-icon {
                @extend  .fa-arrow-right;
                }
                &-control-prev-icon {
                    @extend  .fa-arrow-left;
                }

                // indicators
                &-indicators {
                    position: relative;
                    margin: 0;
                    button {
                        width: 12px;
                        height: 12px;
                        border: 1px solid $white;
                        border-radius: 50%;
                        background-color: transparent;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        opacity: 1;
                        &::after {
                            content: '';
                            width: 7px;
                            height: 7px;
                            background-color: $white;
                            border-radius: 50%;
                            display: none;
                        }
                        &.active {
                            &::after {
                                display: block;
                            }
                        }
                    }
                }

            }
        }

    }

    &.info-modal {
        .modal-dialog {
            width: 100%;
            max-width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
        }
        .modal-content {
            background-color: transparent;
            width: calc(100vw - 45px);
            border: none;
            padding: 0;
        }
        .modal-body {
            padding: 0;
        }
        .modal-header {
            border: none;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
            .btn-close {
                opacity: .8;
                color: $primary;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23645328'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") ;
                &:hover {
                    opacity: 1;
                }
            }
        }
        .modal-title {
            margin-bottom: rem(24px);
            font-size: rem(32px);
        }

        .modal-main-content {
            padding: 3rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .modal-side-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .modal-media {
                margin-top: 7rem;
                position: relative;
                &-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 30%;
                    width: 100%;
                    background: linear-gradient(180deg, $white 0%, rgba(255, 254, 254, 0.00) 59.66%);
                }
                img, picture {
                    width: 100%;
                    height: auto;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .modal-main-content{
                padding: rem(48px) rem(24px) rem(64px);
            }

            .modal-side-content {
                .modal-media {
                    display: none;
                }
            }

        }

        &.dealer-modal {
            .modal-dialog {
                max-height: 100vh;
                height: 100%;
            }
            .modal-content {
                height: calc(100vh - 45px);
            }
            .modal-body{
                height: 100%;
            }
            .dealer-modal-iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}