.dropdown {
    .dropdown-toggle {
        @include ellipsis(none);
        align-items: center;
        gap: rem(5px);
        padding-right: rem(32px) !important;
        position: relative;
        text-align: left;

        &:after {
            @include font-size(12px);
            border: none;
            content: $icon-chevron-down;
            font-family: $icomoon-font-family;
            position: absolute;
            right: rem(10);
            transition: transform ease-in 0.2s;
        }

        span {
            align-self: center;
        }
    }

    .dropdown-menu {
        max-height: rem(250);
        overflow-y: auto;
    }

    @at-root .bg-primary & {
        .btn {
            &.btn-outline-primary {
                border-color: $white;
                color: $white;
            }
        }
    }
}
