.alert {
    @at-root .hero & {
        --#{$prefix}alert-border-color: transparent;
        &-primary {
            --#{$prefix}primary-bg-subtle: #{$primary};
            --#{$prefix}primary-text-emphasis: #{$white};
        }

        .alert-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        p {
            align-self: center;
            padding-right: 1rem;
        }
    }

    @include media-breakpoint-down(md) {
        @at-root .hero & {
            .btn {
                --bs-btn-padding-y: 0.25rem;
                --bs-btn-padding-x: 0.5rem;
                --bs-btn-font-size: 0.875rem;
                --bs-btn-border-radius: 0.25rem;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        @at-root .hero & {
            margin-bottom: 0;

            .alert-wrapper {
                align-items: center;
                flex-direction: row;
            }

            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        @at-root .hero & {
            p {
                @include font-size(18px);
                max-width: rem(840);
            }
        }
    }
}
