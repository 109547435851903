.input-group {
    button {
        &[disabled] {
            cursor: not-allowed;

            > * {
                opacity: 0.5;
            }
        }
    }

    .form-floating {
        + button {
            max-height: rem(58px);
        }
    }
}
