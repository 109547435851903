.pagination {
    flex-wrap: wrap;
    gap: rem(5);

    @at-root .bg-primary & {
        .page-item {
            .page-link {
                border-color: $white;
            }
        }
    }

    .page-link {
        cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
        .page-item {
            &.prev,
            &.next {
                .page-link {
                    span {
                        display: none;
                    }

                    &:before {
                        @include font-size(14px);
                        font-family: "#{$icomoon-font-family}";
                    }
                }
            }

            &.prev {
                .page-link {
                    &:before {
                        content: $icon-chevron-left;
                    }
                }
            }

            &.next {
                .page-link {
                    &:before {
                        content: $icon-chevron-right;
                    }
                }
            }
        }
    }
}
