.video-player {
    &:hover {
        .play {
            background-color: $primary-250;

            &:after {
                border-left-color: $primary-250;
            }
        }
    }

    img,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    iframe {
        transform: scale3d(0, 0, 1);
        transition: transform 0.5s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    }

    .overlay {
        @include flex-center;
        cursor: pointer;
        z-index: 9;
    }

    .play {
        @include flex-center;
        background-color: $gray-900;
        border: 3px solid $white;
        border-radius: 50%;
        color: $white;
        font-size: 0.65em;
        height: 10em;
        opacity: 0.8;
        padding: 0;
        position: relative;
        transition: background-color 0.25s cubic-bezier(0, 0, 0.2, 1);
        width: 10em;
        z-index: 1;

        &:before {
            border-color: transparent;
            border-left-color: $white;
            border-radius: 50% / 5%;
            border-style: solid;
            border-width: 20px 0 20px 30px;
            content: " ";
            font-size: 0.55em;
            height: 0;
            left: 6.5em;
            position: absolute;
            width: 0;
        }

        &:after {
            border-color: transparent;
            border-left-color: $gray-900;
            border-radius: 50% / 5%;
            border-style: solid;
            border-width: 15px 0 15px 22px;
            content: " ";
            height: 0;
            left: 40px;
            position: absolute;
            width: 0;
        }
    }

    &[data-type="vimeo"] {
        &:hover {
            .play {
                background-color: $cyan;

                &:before {
                    background-color: $cyan;
                }
            }
        }
    }

    &.loaded {
        iframe {
            transform: scale3d(1, 1, 1);
        }
    }

    &.native {
        aspect-ratio: 7/5;
        position: relative;
        video, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit:cover;
            object-position: center;
        }
        .overlay-bg-video {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 40%;
            z-index: 1;
        }
        .reveal-video {
            position: absolute;
            top: -2px;
            bottom: -2px;
            background-color: $white;
            width: 40%;
            transition: width .5s ease-out;
            z-index: 1;
            .section-in-view &, .page-builder & {
                width: 0;
            }
        }
    }
    &.slide-left {
        .overlay-bg-video{
            left: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
            opacity: 0.6;
        }

        .reveal-video {
            left: -2px;
        }
    }
    &.slide-right {
        .overlay-bg-video{
            right: 0;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
        .reveal-video {
            right: -2px;
        }
    }

    @media (prefers-reduced-motion) {
        iframe {
            transform: scale3d(1, 1, 1);
        }
    }
}
