&.card-amani-image{
    height: 100%;
    .card-media {
        width: 100%;
        height: 100%;

        img, video, picture {
            height: 100%;
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }
    }
    .card-title {
        font-size: 1.75rem;
    }

    .rich-text {
        padding: 3rem;
        @include media-breakpoint-down(md) { 
            padding: 1rem;
        }
    }

} 