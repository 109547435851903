.navbar-page {
    animation-name: fade-in;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    background-color: $gray-550;
    border-bottom: 1px solid $primary-050;
    border-top: 1px solid $primary-050;
    box-shadow: 0 4px 4px 0 rgba($dark, 0.15);
    display: none;
    position: absolute;
    width: 100%;

    [data-bs-toggle="collapse"] {
        @include flex-center;
        background-color: transparent;
        border: none;
        color: $dark;
        justify-content: space-between;
        padding: 0;
        position: relative;
        width: 100%;

        .fa {
            color: $dark;
        }
    }

    .navbar-nav {
        background-color: transparent;
        position: relative;
        width: 100%;
    }

    .nav-link {
        color: $dark;

        &.active {
            color: $info;
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @at-root .scrolled #{&} {
        display: flex;
        opacity: 1;
    }

    .page-nav-scroll-left,
    .page-nav-scroll-right {
        display: none;
        position: absolute;
        top: calc(50% - 12px);
        cursor: pointer;
        background-color: $light;
    }

    .page-nav-scroll-left {
        left: 0;
        padding-right: rem(7);
        padding-left: rem(25);
    }

    .page-nav-scroll-right {
        right: 0;
        padding-left: rem(7);
        padding-right: rem(25);
    }

    @include media-breakpoint-up(lg) {
        background-color: $light;

        > .container,
        > .container-fluid {
            justify-content: center;
        }

        [data-bs-toggle="collapse"] {
            display: none;
        }

        .navbar-nav {
            align-items: center;
            display: flex;
            justify-content: space-around;
            opacity: 1;
            position: relative;
            top: 0;
            width: 100%;
            flex-wrap: nowrap;
            flex-shrink: 0;

            .nav-link {
                flex-shrink: 0;
            }

            &.scrollable {
                justify-content: flex-start;
                width: 100%;
                overflow: scroll;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
                padding: 0 rem(50);
                -ms-overflow-style: none;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                + .page-nav-scroll {
                    .page-nav-scroll-left,
                    .page-nav-scroll-right {
                        display: block;
                    }
                }
            }
        }
    }

    @media (prefers-reduced-motion) {
        animation-duration: 0;
    }
    &.loading {
        display: flex;
        opacity: 0;
        overflow: hidden;
        .navbar-nav {
            width: auto;
        }
    }
}
