/* dirty override caused by ".was-validated .form-control:valid" outweighing ".form-control.is-invalid" */
.form-control {
    &:valid {
        &.is-invalid {
            background-image: escape-svg($form-feedback-icon-invalid);
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.375rem) center;
            background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
            border-color: $form-feedback-invalid-color;
            padding-right: calc(1.5em + 1.5rem);

            &:focus {
                border-color: $form-feedback-invalid-color;
                box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
            }
        }
    }
}

.form-field {
    .field-validation-error {
        @extend .invalid-feedback;
        display: block;
    }
}