.navbar-toggler {
    @include font-size(21.5px);
    @include line-height(20px, 28px);
    color: $primary;
    display: block;
    padding: 1rem 0;
    text-align: center;
    margin-left: rem(15);

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &[aria-expanded='true'] {
        .nav-icon {
            &:before {
                content: $icon-times;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}