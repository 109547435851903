.navbar-main {
    @import 'navbar-main/navbar-brand';
    @import 'navbar-main/nav-language';
    @import 'navbar-main/nav-main';
    padding: 0;

    @include media-breakpoint-up(lg) {
        background-color: $white;
        border-bottom: 1px solid $primary-050;

        > [class*="container"] {
            flex-wrap: nowrap;
        }
    }
}