.filter-types {
    .dropdown {
        [data-count] {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            .dropdown-toggle,
            .dropdown-menu {
                width: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            .dropdown-toggle {
                display: none !important;
            }

            .dropdown-menu {
                border: none;
                display: flex;
                flex-direction: column;
                position: relative;
            }

            .dropdown-item {
                padding: rem(5);

                &:hover,
                &:focus {
                    --#{$prefix}dropdown-link-hover-color: $primary;
                    --#{$prefix}dropdown-link-hover-bg: transparent;
                }

                &.active,
                &:active {
                    --#{$prefix}dropdown-link-active-color: $primary;
                    --#{$prefix}dropdown-link-active-bg: transparent;
                }
            }
        }
    }
}
