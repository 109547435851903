&.hero-wide {
    .hero-media {
        @include flex-center;

        img,
        video {
            @supports (aspect-ratio: 16/7) {
                aspect-ratio: 16/7;
                width: 100%;
            }
        }
    }

    .hero-content {
        position: relative;
    }

    .bg-offset {
        h1 {
            @include font-size(42px);
            font-weight: 300;
            margin-top: 0.75rem;
            text-transform: uppercase;
        }

        h2 {
            @include font-size(24px);
            color: $primary-250;
            margin-top: 1rem;
            text-align: left;
            width: 100%;
        }
    }

    .social-media {
        align-items: center;
        display: flex;
        gap: rem(7px);
        justify-content: flex-end;
    }

    .hero-cta {
        display: flex;
        gap: rem(20);
        margin-bottom: rem(20);
        position: relative;
    }

    @include media-breakpoint-up(xl) {
        .hero-media {
            img,
            video {
                @supports (aspect-ratio: 16/6.5) {
                    aspect-ratio: 16/6.5;
                }
            }
        }

        .bg-offset {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            top: 50px;

            h1 {
                left: -100px;
                margin-top: 0;
                padding-left: 50px;
                padding-right: 50px;
                position: relative;
                width: 100%;

                &:before {
                    @include font-size(80px);
                    align-items: center;
                    content: attr(data-content);
                    display: flex;
                    font-weight: 400;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    min-height: 25rem;
                    opacity: 0.03;
                    padding: 0 20px;
                    position: absolute;
                    text-transform: uppercase;
                    top: -175px;
                    transform: translateY(-20px);
                    width: 100%;
                }

                &:after {
                    background-color: $gray-100;
                    content: "";
                    height: 100%;
                    left: 0;
                    min-height: rem(400);
                    position: absolute;
                    top: -175px;
                    width: 100%;
                    z-index: -1;
                }

                &:not(.text-xl-center) {
                    padding-left: 100px;
                }
            }

            h2 {
                margin-top: 0;
                padding-right: 150px;
            }
        }

        .hero-cta {
            justify-content: center;
            left: rem(-100);
            padding: rem(25) rem(100);
            width: 100%;

            .btn {
                align-items: center;
                display: flex;
                flex: 0 0 50%;
                justify-content: center;
            }

            i {
                margin-right: rem(10);
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .hero-media {
            img,
            video {
                @supports (aspect-ratio: 16/6.5) {
                    aspect-ratio: 16/6.5;
                }
            }
        }
    }
}
