&-map {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &-wrapper {
        padding-top: 90%;
        position: relative;
    }

    @supports(position: sticky) and (aspect-ratio: auto) {
        aspect-ratio: 1;
        height: auto;
        position: sticky !important;
        transition: top 0.5s ease-in; 

        &-wrapper {
            height: 100%;
            padding-top: 0;
        }

        @at-root .scrolled-up #{&} {
            top: rem(183px);
        }

        @at-root .scrolled-down #{&} {
            top: rem(145px);
        }
    }
}