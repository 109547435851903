.icon-group {
    background-color: rgba($white, 0.85);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    transform: translate3d(-50%, 20%, 0);

    .group-item {
        border-bottom: 1px solid rgba($primary, 0.1);
        padding: rem(16) rem(10);
    }

    i[aria-hidden="true"] {
        @include font-size(32px);
        color: $primary-300;
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.flex-lg-row-reverse {
    .icon-group {
        transform: translate3d(50%, 20%, 0);
        right: 0;
    }    
}