&.card-article-highlight {
    --#{$prefix}card-bg: #{rgba($primary, 0.1)};

    .card-header {
        flex: 1 1 100%;
        padding: 0;

        .badge-group {
            padding: 1.5rem;
            position: absolute;
            top: 0;
        }
    }

    .card-body {
        align-items: stretch;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: center;

        .badge-group {
            padding: 1rem 0;
        }
    }

    .card-img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    .card-footer {
        background-color: transparent;
        padding: 1rem 0;
    }

    .stretched-link {
        font-weight: 600;
    }

    @at-root .bg-primary & {
        --#{$prefix}card-bg: #{rgba($white, 0.9)};

        a {
            &:not(.btn) {
                &.stretched-link {
                    --#{$prefix}link-color: #{$primary};
                    --#{$prefix}link-hover-color: #{rgba($primary, 0.5)};
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}
