.teaser {
    @import "teaser/icon-group";
    @import "teaser/img-component";
    @import "teaser/rich-text";
    padding: 2rem;

    [class^="col"] {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-down(lg) {
        [class^="col"] {
            + [class^="col"] {
                margin-top: 2rem !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}