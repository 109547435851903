.nav-language {
    label,
    .title {
        display: none;
    }

    .nav-link {
        align-items: center;
        color: inherit;
        justify-content: flex-start;
        padding: rem(16px) rem(26px);

        span {
            &.separator {
                &:before {
                    content: "-";
                    margin: 0 5px;
                }

                + span {
                    flex: 1 1 auto;
                }
            }
        }

        &:after {
            @include font-size(12px);
            align-self: center;
            border: none;
            content: "#{$fa-var-chevron-up}";
            font: var(--#{$fa-css-prefix}-font-solid);
            transition: transform ease-in 0.2s;
        }

        &.collapsed {
            &:after {
                transform: scaleY(-1);
            }
        }

        &[aria-expanded="true"] {
            .fa {
                transform: none !important;
            }
        }

        .fi {
            flex: 0 0 rem(20);
            margin-right: rem(10);
        }

        .fa {
            color: $primary-250;
            margin-right: rem(10);
            transition: none !important;
        }
    }

    .collapse,
    .collapsing {
        background-color: $primary-025;
        padding: 0;
        position: relative;
    }

    .dropdown {
        border-bottom: 1px solid $primary-050;
        border-top: 1px solid $primary-050;

        .dropdown-toggle {
            background-color: transparent;
            box-shadow: none;
            border: none;
            font-weight: 600;
            padding: 0 rem(26px);
            position: relative;
            text-align: left;
            width: 100%;

            &:after {
                @include font-size(12px);
                border: none;
                content: "#{$fa-var-chevron-down}";
                font: var(--#{$fa-css-prefix}-font-solid);
                position: absolute;
                right: rem(26px);
                transition: transform ease-in 0.2s;
            }

            span {
                align-self: center;
            }

            &.show {
                &:after {
                    transform: scaleY(-1);
                }
            }
        }

        .dropdown-menu {
            background-color: transparent;
            border: none;
            margin-top: 0 !important;
            position: relative;
            width: 100%;
        }

        .dropdown-item {
            @include font-size(14px);
            font-weight: 600;
            line-height: 1.125;
            padding: rem(10px) rem(42px);
        }

        .dropdown-divider {
            display: none;
        }
    }

    .btn {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
