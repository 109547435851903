@mixin h1 {
    font-weight: 300;
    line-height: line-height($h1-font-size, 60px);
    text-transform: uppercase;
}

@mixin h2 {
    @include font-size($h2-font-size);
    font-weight: 300;
    line-height: line-height($h2-font-size, 38px);
    text-transform: uppercase;
}

@mixin h3 {
    @include font-size($h3-font-size);
    font-weight: 300;
    line-height: line-height($h3-font-size, 38px);
    text-transform: none;
}

@mixin h4 {
    @include font-size($h4-font-size);
    font-weight: 600;
    line-height: line-height($h4-font-size, 28px);
    text-transform: none;
}

@mixin h5 {
    @include font-size($h5-font-size);
    font-weight: 300;
    line-height: line-height($h5-font-size, 28px);
    text-transform: none;
}

@mixin h6 {
    @include font-size($h6-font-size);
    font-weight: 600;
    line-height: line-height($h6-font-size, 26px);
    text-transform: none;
}

h1,
.h1 {
    @include h1;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h2,
.h2 {
    @include h2;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h3,
.h3 {
    @include h3;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h4,
.h4 {
    @include h4;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h5,
.h5 {
    @include h5;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h6,
.h6 {
    @include h6;
    font-family: $font-family-monospace;
    word-break: break-word;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @at-root .bg-primary & {
        color: $white;
    }
}
