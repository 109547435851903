/**
 * @mixin button-variant:
 *    $background,
 *    $border,
 *    $color: color-contrast($background),
 *    $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
 *    $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
 *    $hover-color: color-contrast($hover-background),
 *    $active-background: if($color == $color-contrast-light, shade-color($background,$btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
 *    $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
 *    $active-color: color-contrast($active-background),
 *    $disabled-background: $background,
 *    $disabled-border: $border,
 *    $disabled-color: color-contrast($disabled-background)
 *
 * @mixin button-outline-variant:
 *    $color,
 *    $color-hover: color-contrast($color),
 *    $active-background: $color,
 *    $active-border: $color,
 *    $active-color: color-contrast($active-background)
 */

.btn {
    &.btn-primary {
        @include button-variant(
            $primary,
            $primary,
            $white,
            $black,
            $black,
            $white,
            $blue,
            $blue,
            $white,
            $gray-300,
            $gray-300,
            $white
        );
        @include ripple($black);
        box-shadow: $btn-box-shadow;

        &:focus {
            background-color: $blue;
            border-color: $blue;
        }

        &.disabled {
            &:focus {
                background-color: $gray-300;
                border-color: $gray-300;
            }
        }
    }

    &.btn-secondary {
        @include button-variant(
            $secondary,
            $secondary,
            $white,
            $secondary-575,
            $secondary-575,
            $white,
            $secondary-425,
            $secondary-425,
            $white,
            $gray-300,
            $gray-300,
            $white
        );
        @include ripple($secondary-575);

        &:focus {
            background-color: $secondary-425;
            border-color: $secondary-425;
        }

        &.disabled {
            &:focus {
                background-color: $gray-300;
                border-color: $gray-300;
            }
        }
    }

    &.btn-outline-primary {
        @include button-variant(
            transparent,
            $primary,
            $primary,
            transparent,
            $black,
            $black,
            transparent,
            $blue,
            $blue,
            transparent,
            $gray-300,
            $gray-300
        );
        @include ripple($blue-250);

        &:focus {
            color: $blue;
            border-color: $blue;
        }

        &.disabled {
            &:focus {
                color: $gray-300;
                border-color: $gray-300;
            }
        }
    }

    &.btn-outline-secondary {
        @include button-variant(
            transparent,
            $secondary,
            $secondary,
            transparent,
            $secondary-575,
            $secondary-575,
            transparent,
            $secondary-425,
            $secondary-425,
            transparent,
            $gray-300,
            $gray-300
        );
        @include ripple($secondary-250);

        &:focus {
            color: $secondary-425;
            border-color: $secondary-425;
        }

        &.disabled {
            &:focus {
                color: $gray-300;
                border-color: $gray-300;
            }
        }
    }

    &.btn-outline-white {
        @include button-variant(
            transparent,
            $white,
            $white,
            transparent,
            $gray-650,
            $gray-650,
            transparent,
            $primary-250,
            $primary-250,
            transparent,
            $gray-300,
            $gray-300
        );
        @include ripple($primary-125);

        &:focus {
            color: $primary-250;
            border-color: $primary-250;
        }

        &.disabled {
            &:focus {
                color: $gray-300;
                border-color: $gray-300;
            }
        }
    }
}
