a {
    &:not(.btn) {
        &:focus {
            color: $primary-250;
            text-decoration: underline;
        }

        @at-root .bg-primary #{&} {
            --#{$prefix}link-color: #{$white};
            --#{$prefix}link-hover-color: #{$primary-050};
            --#{$prefix}link-color-rgb : 255, 255, 255; // $white

            &:hover {
                --#{$prefix}link-color-rgb : 242, 244, 247; // $gray-100: #f2f4f7;
            }
        }
    }

    &.anc-page {
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        &.anc-page {
            top: -275px;
        }
    }
}
