&-results {
    &-filters {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10px);

        .btn {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            gap: rem(20px);

            .btn {
                width: auto;
            }
        }
    }
}