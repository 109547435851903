.img-container {
    display: flex;
    gap: rem(20px);
    width: 100%;

    &.curtain {
        flex-wrap: wrap;
        gap: 0 rem(20px);

        > * {
            flex: 0 1 calc(33.33% - 20px);

            &:nth-child(3n + 2) {
                margin-top: rem(20);
            }
        }
    }

    &.masonry {
        flex-wrap: wrap;

        > * {
            flex: 1 1 calc(50% - 20px);

            &:nth-child(5n + 1) {
                flex-basis: 100%;
            }
        }
    }

    &.masonry-mix {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        overflow: hidden;

        .img-component {
            aspect-ratio: 4 / 3;
            grid-column: auto;
            grid-row: auto;
            height: auto;
            overflow: hidden;
            width: 100%;

            &:nth-child(16n + 1),
            &:nth-child(16n + 9) {
                aspect-ratio: 16 / 7;
                grid-column: auto / span 2;
                grid-row: auto;
            }

            &:nth-child(16n + 4),
            &:nth-child(16n + 13) {
                aspect-ratio: 0.646;
                grid-column: auto;
                grid-row: auto / span 2;
            }
        }

        picture {
            display: flex;
            height: 100%;
            width: 100%;
        }

        .img-fluid {
            flex: 1;
            object-fit: cover;
            object-position: center;
        }
    }

    &.masonry-media {
        display: block;
        gap: 0;

        .masonry-media-items {
            display: flex;
            flex-wrap: wrap;
        }

        .media-item {
            position: relative;
            width: 33.33333334%;

            &:not(:last-of-type) {
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            img,
            picture,
            video {
                width: 100%;
                height: auto;
            }

            &.loading {
                img,
                picture,
                video {
                    background-color: $primary-400;
                    height: 250px;
                    filter: blur(5px);
                }
            }

            .media-overlay {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: transparentize($black, 0.4);
            }

            .media-view {
                display: none;
                position: absolute;
                z-index: 1;
                width: 128px;
                height: 128px;
                border: 1px solid $primary-400;
                background-color: transparentize($white, 0.8);
                backdrop-filter: blur(2px);
                border-radius: 50%;
                padding: rem(30);
                top: calc(50% - 64px);
                left: calc(50% - 64px);
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                span {
                    color: $white;
                    font-weight: 600;
                }

                &.img {
                    width: 80px;
                    height: 80px;
                    top: calc(50% - 40px);
                    left: calc(50% - 40px);

                    span {
                        font-size: rem(32px);
                        line-height: rem(32px);
                        font-weight: 200;
                    }
                }
            }
            
            &:hover {
                .media-overlay,
                .media-view {
                    display: flex;
                }
            }
        }

        .masonry-media-load-more {
            text-align: center;
        }
    }
}

.img-component {
    display: flex;
    align-items: center;
    height: 100%;
}
