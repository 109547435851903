.badge {
    @include line-height($badge-font-size, 20px);
    @include border-radius(var(--#{$prefix}badge-border-radius), $badge-border-radius);
    text-transform: capitalize;

    &.badge-primary {
        background-color: $primary;
        color: $white;
        font-weight: 300;
    }

    &.badge-outline-primary {
        @extend .rounded-pill;
        border: 1px solid $primary;
        color: $primary;
    }

    &.badge-date {
        max-width: rem(65);
        padding: rem(5) rem(10) rem(5);
        text-transform: lowercase;
        white-space: normal;
    }

    &.badge-select-option {
        align-items: center;
        background-color: transparent;
        border: 2px solid $cyan-200;
        color: $primary;
        display: inline-flex;
        font-size: rem(18);
        font-weight: 400;
        padding: rem(4) rem(8);

        [class*="icon-"] {
            font-size: rem(13);
            margin-right: rem(10);
        }

        &:hover,
        &:focus {
            box-shadow: $form-check-input-focus-box-shadow;
        }

        &:focus-visible {
            box-shadow: $form-check-input-focus-box-shadow;
            outline: 0;
        }

        &:active {
            border-color: $primary;
        }
    }

    @include media-breakpoint-up(sm){
        &.badge-select-option {
            padding: rem(8) rem(16);
        }
    }
}