&.carousel-options-listing {
    .carousel-inner {
        padding-bottom: rem(50);
        @include media-breakpoint-down(sm){
            padding-bottom: 0
        }
    }

    .carousel-indicators {
        @include flex-center;
        gap: 0.5rem;
        margin: 0 5%;
        position: relative;
        text-align: center;

        [data-bs-target] {
            border: 1px solid $dark;
            border-radius: 50%;
            height: rem(10);
            position: relative;
            width: rem(10);

            &:before {
                background-color: $dark;
                border-radius: 50%;
                height: 6px;
                left: 2px;
                position: absolute;
                top: 2px;
                width: 6px;
            }

            &.active {
                &:before {
                    content: "";
                }
            }

            &.carousel-control-prev,
            &.carousel-control-next {
                border: none;
                height: rem(40);
                opacity: 1;
                width: rem(40);

                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    align-items: center;
                    background: none;
                    display: inline-flex;
                    justify-content: center;
                    opacity: 1;
                    text-indent: 0;

                    &:after {
                        color: $dark;
                        font: var(--#{$fa-css-prefix}-font-solid);
                    }
                }

                .carousel-control-prev-icon {
                    &:after {
                        content: "#{$fa-var-arrow-left}";
                    }
                }

                .carousel-control-next-icon {
                    &:after {
                        content: "#{$fa-var-arrow-right}";
                    }
                }
            }
        }
    }

    .carousel-caption {
        bottom: -#{rem(60)};
        font-weight: 600;
        left: 0;
        right: auto;
        @include media-breakpoint-down(sm){
            bottom: 0;
            position: relative;
        }
    }
}
