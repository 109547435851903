// top navigation
.nav-language {
    @include flex-center;
    position: relative;

    .nav-link {
        @include flex-center;
        position: relative;
        text-decoration: none;

        &:after {
            @include font-size(12px);
            border: none;
            content: $icon-chevron-right;
            font-family: $icomoon-font-family;
            margin-left: 5px;
            transition: transform ease-in 0.2s;
        }

        &.show {
            &:after {
                content: $icon-chevron-down;
            }
        }

        span {
            &.separator {
                &:before {
                    content: "-";
                    margin: 0 5px;
                }
            }
        }

        .fi {
            flex: 0 0 20px;
            margin-right: 5px;
        }

        .fa {
            color: $primary-250;
            margin-right: rem(10);
        }
    }

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        border: 0;

        .title {
            @include h6;
            font-weight: 400;
            margin-bottom: 1rem;
        }

        .nav-link {
            &:after {
                content: $icon-chevron-down;
            }

            &.show {
                &:after {
                    transform: scaleY(-1);
                }
            }

            &[aria-expanded="true"] {
                background-color: $white;
                box-shadow: 0 1px 0px 2px $white, -1px 0px 0px 2px $gray-250, 2px 0px 0 1px $gray-250;
            }
        }

        .collapse,
        .collapsing {
            padding: 0 20px 20px;
            position: absolute;
            right: -20px;
            top: rem(46);

            @at-root .scrolled-down #{&} {
                display: none;
            }
        }

        form {
            background-color: $white;
            border: 1px solid $gray-250;
            border-top: none;
            box-shadow: 0 4px 20px 0 rgba($black, 0.14);
            padding: rem(20) rem(25);
            position: relative;
            right: -3px;
            width: rem(300);
        }

        label {
            @include font-size(16px);
            font-weight: 600;
            margin-bottom: rem(10);
        }

        .dropdown-toggle,
        .dropdown-menu {
            background-color: $white;
            border: 1px solid rgba($primary, 0.5);
            width: 100%;
        }
    }
}
