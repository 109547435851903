form {
    @import "form/button";
    @import "form/dropdown";
    @import "form/lists";
    @import "form/input-group";
    @import "form/textarea";
    @import "form/validation";

    .form-check-group {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 1.5rem;

        .form-check-inline {
            background-color: $white;
            border: 1px solid $primary-050;
            margin-right: 0;
            min-height: auto;
            padding: 0.75rem 1rem 0.75rem 2.5rem;

            + .form-check-inline {
                border-left: 0;
            }
        }
    }

    .form-field {
        label {
            font-weight: 600;
            margin-bottom: 5px;
        }

        input,
        textarea {
            &:active,
            &:focus {
                border-color: $blue;
                outline: none;
                box-shadow: none;
            }
        }
    }
}
