&.hero-side {
    padding: 0;

    .hero-content {
        padding-top: rem(20);
    }

    @include media-breakpoint-up(md) {
        .hero-media {
            img {
                max-height: rem(350);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(100) 0;

        .hero-media {
            position: relative;

            &:after {
                background-color: $light;
                content: " ";
                height: 100%;
                position: absolute;
                right: -#{rem(100)};
                top: -#{rem(100)};
                width: 100%;
                z-index: -1;
            }

            img {
                max-height: unset;
            }
        }

        .hero-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            padding-top: 0;
        }
    }
}
