&-detail {
    &-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: rem(5);

        p,
        a {
            font-size: rem(16);
            color: $primary;
        }

        a {
            text-decoration: underline;
        }

        .fa-solid {
            margin-right: rem(10);
            min-width: rem(16);
        }

        .address-wrapper {
            display: block;
            line-height: 1.2;
            max-width: 100%;
            overflow: hidden;
            text-align: left;
        }

        .btn {
            align-items: center;
            display: flex;
            flex: 1 1 100%;
            font-weight: 400;
            max-width: 100%;

            span {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.btn-light {
                text-decoration: none;
            }
        }
    }

    &-online {
        &-info {
            &-wrapper {
                @include media-breakpoint-up(lg) {
                    margin-top: -15%;
                }
            }
        }
    }

    &-map {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        &-wrapper {
            padding-top: 65.5%;
            position: relative;
        }
    }

    &-types {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem(20);
        margin-top: rem(40);
    }

    &-type {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
            font-size: rem(22);
        }

        &.premium {
            color: $orange;
        }

        &-info {
            &-header {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                margin-bottom: rem(30);
            }

            &-icon {
                display: flex;
                flex-direction: column;
                font-size: rem(20);

                &.premium {
                    color: $orange;
                }

                &.fabricator {
                    color: $fabricator-color;
                }
                
            }

            &-title {
                font-size: rem(24);
                margin-bottom: rem(20);
                font-weight: 700;
            }

            &-badge-list {
                display: flex;
                flex-wrap: wrap;
                gap: rem(10);
            }

            p {
                font-size: rem(14);
                font-weight: 600;
            }

            &-new {
                background-color: $gray-100;
                margin-bottom: rem(30);
                padding: rem(30);
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
                &-title {
                    font-weight: 700;
                    color: $black;
                    margin-bottom: rem(15);
                }
                &-block {
                    &:not(:last-of-type){
                        margin-bottom: rem(30);
                    }
                }
                &-row {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: rem(5);
                }
                &-item {
                    width: 165px;
                    border: 1px solid $gray-400;
                    padding: rem(10);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @include media-breakpoint-down(sm) {
                        width: calc(50% - rem(5));
                    }

                    &-icon {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 3.15rem;
                        color: $primary;
                    }
                    &-title {
                        font-size: 1rem;
                        font-weight: 300;
                        color: $primary;
                        margin-top: rem(10);
                        text-align: center;
                        line-height: 1.3rem;
                        span {
                            text-align: left;
                        }
                    }
                }
                
            }
        }
    }

    &-subtype {
        @extend .btn;
        @extend .btn-outline-primary;
        @extend .rounded-1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: rem(5);
        cursor: initial;
        font-size: rem(10);
        line-height: rem(20);
        padding: rem(4) rem(8);
        

        &:focus {
            background-image: none;
            border-color: $primary;
            color: $primary;
        }
    }
}
