&.card-product-var3 {
    --#{$prefix}card-bg: #{$white};
    --#{$prefix}card-cap-bg: transparent;
    --#{$prefix}card-cap-padding-y: 1rem;
    --#{$prefix}card-cap-padding-x: 1rem;
    --#{$prefix}card-spacer-y: 0.5rem;
    --#{$prefix}card-spacer-x: 1rem;
    --#{$prefix}card-color: #{$dark};
    --#{$prefix}card-height: 100%;

    ul {
        @extend .check;
        padding-left: 1.25rem;

        li {
            &:before {
                line-height: 1.6;
                left: -1.25rem;
            }
        }
    }

    .card-header {
        align-items: center;
        display: flex;
    }

    picture {
        @extend .ratio;
        @extend .ratio-16x9;
    }

    .card-img {
        object-fit: contain;
    }

    .card-text {
        @include font-size(14px);

        + .card-text {
            margin-top: 0.75rem;
        }
    }

    .card-body {
        margin-bottom: 3rem;
        position: relative;
    }

    .card-footer {
        @include font-size(14px);
        bottom: -3rem;
        color: $primary;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;

        .card-header {
            .card-title {
                text-decoration: underline;
            }
        }

        .card-footer {
            > span {
                text-decoration: underline;
            }
        }
    }

    @at-root .bg-primary & {
        --#{$prefix}card-color: #{$white};

        .card-title {
            &.h2 {
                color: $primary-050;
            }
        }

        .form-check-label,
        .check {
            color: var(--#{$prefix}card-color);
        }
    }

    @include media-breakpoint-down(md) {
        --#{$prefix}card-height: auto;
        --#{$prefix}card-spacer-y: 0;
        --#{$prefix}card-cap-padding-y: 0.5rem;
        --#{$prefix}card-cap-padding-x: 0.5rem;
        --#{$prefix}card-spacer-y: 0.5rem;
        --#{$prefix}card-spacer-x: 0.5rem;
        flex-direction: row;

        .card-header {
            flex: 1 0 30%;
        }

        .card-body {
            flex: 1 1 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        --#{$prefix}card-cap-padding-y: 0.5rem;
        --#{$prefix}card-cap-padding-x: 0;
        --#{$prefix}card-spacer-y: 0.5rem;
        --#{$prefix}card-spacer-x: 0.5rem;

        .card-header {
            display: none;
        }

        .card-body {
            border: 1px solid $primary;
            margin-bottom: 0;
        }

        .card-text {
            + .card-text {
                display: none;
            }
        }

        .card-footer {
            bottom: 0;
            position: relative;
        }
    }
}
