&-search {
    @import "../form/dropdown";
    position: relative;

    &-media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        picture {
            height: 100%;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .nav {
        &-link {
            border: none;
            background-color: $gray-250;
            color: $body-color;
            // fix for active bold 'jump'
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &.active {
                background-color: $white;
                color: $primary;
                font-weight: 700;
                border-right: 1px solid $gray-600;
            }
            &::after {
                // fix for active bold 'jump'
                content: attr(data-text);
                content: attr(data-text) / "";
                height: 0;
                visibility: hidden;
                overflow: hidden;
                user-select: none;
                pointer-events: none;
                font-weight: 700;
            }
        }

        &-item {
            &:not(:last-of-type) {
                .nav-link:not(.active) {
                    border-right: 1px solid $gray-600;
                }
            }
            &:last-of-type .nav-link.active {
                border-right: none;
            }
            
        }

    }

    &-content {
        position: relative;
        z-index: 1;

        [data-type-target] {
            display: none;

            &.active {
                display: flex;
            }
        }
    }

    .info-window {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        ul {
            padding-left: 0;
        }

        button {
            width: 100%;
        }
    }

    .dropdown {
        .dropdown-menu {
            box-shadow: $input-focus-box-shadow;
        }

        .dropdown-item {
            .form-check-label {
                color: var(--#{$prefix}dropdown-link-color) !important; // override validation
            }
        }
    }

    .dropdown-wrapper {
        align-items: center;

        .dropdown-title {
            margin-bottom: rem(10px);
        }

        .dropdown-toggle {
            box-shadow: 0 0 12px 0 rgba(51,51,51,.1);

            &.is-valid,
            &.is-invalid {
                background-image: escape-svg($form-select-indicator) !important;
                background-position: $form-select-bg-position !important;
                background-size: $form-select-bg-size !important;

                > span {
                    overflow: auto;
                    width: 100%;
                }
            }

            &.is-invalid {
                > span {
                    color: $form-feedback-invalid-color;
                }
            }

            span {
                padding-right: 5px;
            }
        }
        @include media-breakpoint-down(md) {
            &:nth-of-type(2){
                h3 {
                    font-size: 1rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-media {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        .dropdown-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 0;

            .dropdown-title {
                margin-bottom: 0;
            }

            .dropdown {
                margin-left: rem(15);
                
                .dropdown-toggle {
                    @include h3;
                    border: none;
                }

                .dropdown-menu {
                    border: none;
                }
            }
        }
    }
}
