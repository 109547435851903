&.card-product-var2 {
    height: 100%;

    .card-title {
        @include h6;
        color: $primary;
        font-weight: 700;
    }

    .card-body,
    .card-footer {
        padding-left: 0;
        padding-right: 0;
    }

    .check {
        color: $primary;
        font-weight: 600;
    }

    .form-check-label {
        color: $primary;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}
