.nav-portal {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;

        .nav-link {
            &[aria-current="page"] {
                background-color: $white;
                box-shadow: 0 1px 0px 2px $white, -1px 0px 0px 2px $gray-250, 2px 0px 0 1px $gray-250;
            }
        }
    }
}
