.badge-group {
    display: flex;
    gap: rem(10);
    flex-wrap: wrap;

    &:empty {
        margin-top: 0 !important;
    }

    @include media-breakpoint-up(sm){
        gap: rem(20);
    }
}