@use "sass:math";

[aria-label="breadcrumb"] {    
    display: flex;
    margin: auto;
    position: relative;

    &.has-overflow {
        &:before {
            background-image: linear-gradient(to left, $white, $white 30%, rgba($white, 0));
            background-size: 100%;
            content: "";
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 15%;
            z-index: 1;
        }
    
        &:after {
            @include font-size(20px);
            color: $primary;
            content: "\232a";
            font-family: sans-serif;
            font-weight: 700;
            height: 100%;
            line-height: 1.8;
            position: absolute;
            right: math.div($container-padding-x, 2);
            top: 0;
            z-index: 2;
        }
    }

    .breadcrumb {
        display: inline-flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        position: relative;
        white-space: nowrap;
        width: 100%;
    
        .breadcrumb-item {
            display: inline-flex;
    
            + .breadcrumb-item {
                &:before {
                    font-family: monospace;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $primary-075;
        border-top: 1px solid $primary-075;
    }

    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, "xl");
    }

    @include media-breakpoint-up(xxl) {
        max-width: map-get($container-max-widths, "xxl");
    }
}
