:root {
    --delay-time: 0.5s;
}

.drop {
    animation: drop 0.3s linear forwards var(--delay-time);
}

@keyframes drop {
    0% {
        transform: translateY(-200px) scaleY(0.9);
        opacity: 0;
    }
    5% {
        opacity: 0.7;
    }
    50% {
        transform: translateY(0px) scaleY(1);
        opacity: 1;
    }
    65% {
        transform: translateY(-17px) scaleY(0.9);
        opacity: 1;
    }
    75% {
        transform: translateY(-22px) scaleY(0.9);
        opacity: 1;
    }
    100% {
        transform: translateY(0px) scaleY(1);
        opacity: 1;
    }
}
