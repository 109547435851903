.navbar-brand {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: rem(100);

    > img {
        max-height: 100%;
        max-width: 100%;
    }

    @include media-breakpoint-down(lg) {
        bottom: -4px;
        position: absolute;

        .landingspage &  {
            bottom: 0;
            position: relative;
        }
    }

    @include media-breakpoint-down(md) {
        bottom: 0;
    }

    @include media-breakpoint-up(md) {
        width: rem(148);
    }

    @include media-breakpoint-up(lg) {
        padding: rem(24) 0;
        width: rem(172);
    }
}
