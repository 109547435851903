&.card-info-usp {
    --#{$prefix}card-border-color: #{$primary-050};
    --#{$prefix}card-border-width: 1px;

    &:hover,
    &:focus {
        .card-title {
            text-decoration: none;
        }
    }

    .card-img-top {
        height: 100%;
        margin: rem(30) auto 0;
        max-height: rem(100);
        object-fit: contain;
        width: 100%;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        padding: rem(28) rem(24);
        text-align: center;
    }

    .card-title {
        @include flex-center;
    }

    .card-text {
        @include flex-center;

        > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @at-root .bg-primary & {
        --#{$prefix}card-color: #{$dark};
        --#{$prefix}card-border-color: #{$primary};

        .card-title {
            color: var(--#{$prefix}card-color);
        }
    }
}