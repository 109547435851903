.nav-alphabet {
    display: inline-flex;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .btn {
        position: relative;

        &:after {
            background-color: rgba($white, 0.2);
            content: '';
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: rem(1px);
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}

.alphabet-results {
    > [id] {
        border-bottom: 1px solid $gray-500;
        position: relative;
        scroll-margin-top: rem(150);

        &:before {
            @include font-size(34px);
            content: attr(id);
            display: block;
            left: $grid-gutter-width * 0.5;
            position: absolute;
            top: -2.5rem;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    @include media-breakpoint-up(md) {
        > [id] {
            scroll-margin-top: rem(180);
        }
    }
}
