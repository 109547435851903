&.card-amani-full-width {
    position: relative;

    .card-media {
        position: relative;
        margin-left: calc(((100vw - 100%) / 2) * -1);
        margin-right: calc(((100vw - 100%) / 2) * -1);

        img,
        video,
        picture {
            width: 100%;
        }

        &-overlay {
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 25%);
        }
    }

    .card-content {
        position: absolute;
        inset: 0;
        padding-top: 4rem;
        z-index: 1;
        
        @include media-breakpoint-down(md) {
            position: relative;
            padding-top: rem(24px);
        }
    }
}
