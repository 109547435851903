table {
    &.table {
        tr {
            &[data-href] {
                cursor: pointer;
                position: relative;

                &:hover,
                &:active,
                &:focus {
                    background-color: rgba($primary, 0.1);
                }

                // keyboard accessibility
                &:focus-within {
                    background-color: rgba($primary, 0.1);
                }

                // https://github.com/twbs/bootstrap/issues/28608
                // https://www.w3.org/TR/CSS21/visuren.html#propdef-position
                th {
                    &[scope="row"] {
                        background-color: transparent;
                        border: none;
                        height: auto;
                        min-width: 100%;
                        padding: 0;
                        position: absolute;
                    }
                }
            }
        }
    }
}
