footer {
    &[role="contentinfo"] {
        background: $primary;
        color: $white;

        .nav-icon {
            display: flex;
            position: relative;

            &:before,
            &:after {
                @include font-size(14px);
                color: rgba($white, 0.6);
                font-family: $icomoon-font-family;
                
            }

            &:before {
                border-left: 1px solid rgba($white, 0.6);
                content: '';
                height: 100%;
                width: 100%;
            }

            &:after {
                content: $icon-chevron-down;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .2s ease-in-out;
            }

            &[aria-expanded="true"] {
                &:after {
                    transform: translateY(-50%) scale(-1);
                }
            }
        }

        .nav-title {
            @include font-size(14px);
            color: $white;
            display: flex;
            font-weight: 700;
            line-height: line-height(14px, 20);
            margin-bottom: rem(10);
            position: relative;
            text-transform: none;

            .nav-link {
                flex: 1 1 calc(100% - 40px);
            }

            .nav-icon {
                align-items: center;
                flex: 0 0 40px;
                justify-content: center;
            }
        }

        .nav {
            flex-wrap: nowrap;

            .nav-link {
                @include font-size(14px);
                font-weight: 400;
                line-height: line-height(14px, 18);
                margin: rem(5) 0;
            }

            &.flex-column {
                margin-bottom: rem(10);

                .nav-link {
                    padding: 0;
                }
            }
        }

        .nav-link {
            --#{$prefix}nav-link-color: #{$white};
            transition: all 0.2s ease-out;
        }

        .text-underline {
            text-decoration: underline;
        }

        .nav-link-highlight {
            @extend .nav-link;
            --#{$prefix}nav-link-color: #00ae00; //#33d033

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
            }
        }

        .social-media {
            display: flex;
            justify-content: flex-start;

            .nav-link {
                @extend .fa-border;
                --fa-border-color: rgba(0, 106, 224, 0.2);
                --fa-border-radius: 50%;
                --fa-border-padding: 7px 9px;
                --fa-border-width: 1px;
                color: $white;
                margin-left: 7px;
                transition: all ease-in 0.2s;

                &:hover,
                &:active,
                &:focus {
                    --fa-border-color: #{$blue-750};
                    background-color: $white;
                    color: $primary;
                    text-decoration: none;
                }
            }
        }

        .rich-text,
        .fr-view {
            @include font-size(14px);
            margin-bottom: rem(16);
            --#{$prefix}link-color-rgb: #{$white};
        }

        .subfooter {
            @include flex-center;
            @include font-size(14px);
            border-top: 0;
            flex-direction: column;
            text-align: center;
            padding: rem(33) 0 rem(73) 0;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            .branding-logo {
                display: none;
            }

            .subfooter {
                .nav {
                    flex-direction: column;

                    &-link {
                        line-height: 1;
                        margin: 0;
                    }
                }
            }

            .social-media {
                flex-wrap: wrap;
            }
        }

        @include media-breakpoint-up(md) {
            .nav-icon {
                &:after {
                    display: none;
                }
            }

            .nav {
                &.collapse,
                &.collapsing {
                    display: flex !important;
                }
            }

            .nav-title {
                display: inline-flex;

                .nav-icon {
                    display: none;
                }
            }

            .subfooter {
                align-content: center;
                border-top: 1px solid $blue-750;
                flex-direction: row;
                justify-content: space-between;
                text-align: left;
                width: auto;

                .nav,
                .copyright {
                    opacity: 0.6;
                }
            }

            .collapsing {
                height: auto;
                transition: none;
            }
        }

        @include media-breakpoint-up(xl) {
            .social-media {
                justify-content: flex-end;
            }
        }
    }
}
