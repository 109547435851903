.ktc-checkbox-list,
.ktc-radio-list {
    display: flex;
    flex-wrap: wrap;
}

.ktc-checkbox,
.ktc-radio {
    @extend .form-check;
    @extend .form-check-inline;
    display: inline-block;

    .form-control {
        @extend .form-check-input;
        padding: 0;
        border: 1px solid $primary;
    }

    label {
        @extend .form-check-label;
        font-weight: 400 !important;
        margin-bottom: 0 !important;
    }
}

.list-vertical {
    .ktc-checkbox,
    .ktc-radio {
        width: 100%;
        margin-right: 0;

        &:not(:last-of-type) {
            margin-bottom: rem(5);
        }
    }
}
