.author {
    align-items: center;
    background-color: $light;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    img {
        aspect-ratio: 1;
        border-radius: 50%;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 1.5rem;
        padding: 1.5rem 5%;
    }

    @include media-breakpoint-up(md) {
        gap: 2rem;
        padding: 2rem 10%;
    }

    @include media-breakpoint-up(lg) {
        gap: 3rem;
        padding: 3rem 15%;
    }
}
