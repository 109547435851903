&.card-experience-var1 {
    background-color: $dark;
    color: $white;
    height: 100%;

    .card-img {
        height: 100%;
        object-fit: cover;
    }

    .card-img-overlay {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: rem(20) rem(10);

        > * {
            position: relative;
            z-index: 1;
        }

        &:after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            bottom: 0;
            content: "";
            height: 33%;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .card-title {
        color: $white;
    }

    .card-text {
        color: $white;
    }

    @include media-breakpoint-up(md) {
        .card-img-overlay {
            padding: rem(40) rem(30);
        }
    }
}
