&.hero-viewport {
    height: 100vh;
    width: 100%;
    overflow-y: clip;

    .hero-inner {
        height: 150vh;
        width: 100%;
    }

    .hero-media {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        img, video, picture, figure {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .hero-content {
        z-index: 1;
        position: sticky;
        bottom: 3rem;
        left: 0;
        right: 0;
        img, svg {
            width: 360px;
            max-width: 70%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
        p {
            text-align: center;
            color: #C7C3B4;
            margin: 1rem 0 0;
            font-weight: 300;
        }
        b, strong {
            font-size: rem(20px);
            color: $white;
        }
    }
    .hero-overlay {
        &-top, &-bottom, &-scrolled {
            position: absolute;
            left: 0;
            right: 0;
            mix-blend-mode: multiply;
            transition: opacity .2s ease-in;
        }
        &-top {
            top: 0;
            bottom: 65%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
            opacity: 1;
            .scrolled & {
                opacity: 0;
            }
           
        }
        &-bottom {
            bottom: 0;
            top: 35%;
            background: linear-gradient(12deg, rgba(0, 0, 0, 0.60) 19.44%, rgba(0, 0, 0, 0.00) 50%);
            opacity: 1;
            .scrolled & {
                opacity: 0;
            }
            
        }
        &-scrolled {
            bottom: 0;
            top: 0;
            background: transparentize($color: $black, $amount: .4);
            opacity: 0;
            .scrolled & {
                opacity: 1;
            }
        }
    }
    + section {
        // section next to hero is part of component
        scroll-snap-align: end !important;
        scroll-margin: 0 !important;
        @include media-breakpoint-up(lg) {
            padding: rem(64px) 0 rem(48px);
        }
    }
}