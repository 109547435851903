%expand-width {
    .nav-title {
        animation-direction: reverse;
        animation-play-state: paused;
    }
}

@keyframes shrink {
    from {
        max-width: rem(360);
        padding-right: rem(10);
    }
    to {
        max-width: 0;
        padding-right: 0;
    }
}

.side-menu {
    align-items: stretch;
    background-color: $primary-400;
    display: flex;
    flex-direction: row;
    gap: 1px;
    justify-content: center;
    position: fixed;
    transition: transform linear 0.2s;

    .nav-link {
        @include flex-center;
        background-color: $primary;
        color: $white;
        flex: 1;
        flex-direction: column;
        height: auto;
        min-height: rem(50);
        padding: rem(5) 0;
        overflow: hidden;
        position: relative;

        &:hover,
        &:active,
        &:focus {
            background-color: $dark;
            text-decoration: none;
        }
    }

    .nav-title {
        @include font-size(10px);
        line-height: 1.5;
        text-transform: uppercase;
        white-space: nowrap;
    }

    i[aria-hidden="true"] {
        line-height: 1.5;
        text-align: center;
        width: rem(50);
    }

    &:hover {
        @extend %expand-width;
    }

    &:focus-within {
        @extend %expand-width;
    }

    @include media-breakpoint-down(sm) {
        .nav-title {
            @include font-size(9px);
            white-space: normal;
            text-transform: none;
            text-align: center;
            margin-bottom: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 50%;
        z-index: 999;

        .nav-link {
            flex: 1 1 auto;
            flex-direction: row;
            height: rem(50);
            margin-bottom: 1px;
        }

        .nav-title {
            @include font-size(12px);
            animation-name: shrink;
            animation-duration: 0.5s;
            animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            animation-delay: 5s;
            animation-fill-mode: forwards;
            flex-grow: 1;
            max-width: rem(360);
            padding-right: rem(10);
        }
    }

    @include media-breakpoint-down(lg) {
        bottom: 0;
        width: 100%;

        @at-root .scrolled-down & {
            overflow: hidden;
            pointer-events: none;
            transform: translateY(100%);
        }
    }

    @media (prefers-reduced-motion: reduce) {
        transition-duration: 0;

        .nav-title {
            animation-duration: 0;
        }
    }
}
