section {
    padding: rem(35) 0;

    @include media-breakpoint-down(lg) {
        .row {
            [class^="col-"] {
                + [class^="col-"] {
                    margin-top: rem(12);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: rem(70) 0;
    }


    &.media-overflow {
        overflow-x: clip;
        .overlap-left {
            @include media-breakpoint-up(xl) {
                margin-left: calc(((100vw - (map-get($container-max-widths, "xl") - $container-padding-x)) / 2  ) * -1);
                width: calc(50% + ((100vw - (map-get($container-max-widths, "xl") - $container-padding-x)) / 2  ) );
            }
        
            @include media-breakpoint-up(xxl) {
                margin-left: calc(((100vw - (map-get($container-max-widths, "xxl") -  $container-padding-x) )/ 2 ) * -1);
                width: calc(50% + ((100vw - (map-get($container-max-widths, "xxl") - $container-padding-x)) / 2  ) );
            }

            @include media-breakpoint-down(md) {
                order: 1;
            }
        }

        .overlap-right {
            @include media-breakpoint-up(xl) {
                margin-right: calc(((100vw - (map-get($container-max-widths, "xl") - $container-padding-x)) / 2  ) * -1);
                width: calc(50% + ((100vw - (map-get($container-max-widths, "xl") - $container-padding-x)) / 2  ) );
            }
        
            @include media-breakpoint-up(xxl) {
                margin-right: calc(((100vw - (map-get($container-max-widths, "xxl") -  $container-padding-x) )/ 2 ) * -1);
                width: calc(50% + ((100vw - (map-get($container-max-widths, "xxl") - $container-padding-x)) / 2  ) );
            }

            @include media-breakpoint-down(md) {
                order: 1;
            }
        }

    }
}
