&.card-article-var3 {
    --#{$prefix}card-bg: transparent;
    @include media-breakpoint-up(md) {
        picture {
            display: block;
            margin-bottom: rem(30);
            position: relative;

            &:after {
                border: 1px solid $primary-050;
                content: "";
                height: 85%;
                left: rem(-30);
                position: absolute;
                top: 7.5%;
                width: calc(100% + 60px);
                z-index: 0;
            }
        }

        .card-body {
            padding: 0 rem(30);
        }

        .card-title {
            margin-bottom: rem(30);
        }

        .card-img {
            position: relative;
            z-index: 1;
        }
    }
}
